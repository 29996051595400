const ArrowToTopLeftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6">
      <g>
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M9.414 8l8.607 8.607-1.414 1.414L8 9.414V17H6V6h11v2z" />
      </g>
    </svg>
  );
}

export default ArrowToTopLeftIcon;