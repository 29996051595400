const PostPurchaseListItemSkeleton = () => (
  <div className="body-content px-2 py-10 mx-auto">
    <div className="flex flex-wrap gap-4">
      <div className="flex flex-wrap md:flex-nowrap gap-2 lg:gap-16 p-4 w-full border-2 border-gray-200 rounded-lg">
        <div className="w-full md:w-2/3 shrink-0">
          <div className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></div>       
          <div className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></div>


          <p className="mt-10 leading-relaxed mb-2 w-1/3 h-3 animate-pulse bg-gray-400"></p>
          <p className="leading-relaxed mb-3 w-full rounded-md h-3 animate-pulse bg-gray-400"></p>
        </div>

        <div className="self-center w-full flex md:block md:w-1/3">
          <div className="w-full sm:mb-4 h-14 animate-pulse bg-gray-500 md:rounded-md rounded-l-md rounded-r-none"></div>
          <div className="w-full sm:mb-4 h-14 animate-pulse bg-gray-200 md:rounded-md rounded-r-md rounded-l-none"></div>
        </div>
      </div>

      <div className="flex flex-wrap md:flex-nowrap gap-2 lg:gap-16 p-4 w-full border-2 border-gray-200 rounded-lg">
        <div className="w-full md:w-2/3 shrink-0">
          <div className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></div>       
          <div className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></div>


          <p className="mt-10 leading-relaxed mb-2 w-1/3 h-3 animate-pulse bg-gray-400"></p>
          <p className="leading-relaxed mb-3 w-full rounded-md h-3 animate-pulse bg-gray-400"></p>
        </div>

        <div className="self-center w-full flex md:block md:w-1/3">
          <div className="w-full sm:mb-4 h-14 animate-pulse bg-gray-500 md:rounded-md rounded-l-md rounded-r-none"></div>
          <div className="w-full sm:mb-4 h-14 animate-pulse bg-gray-200 md:rounded-md rounded-r-md rounded-l-none"></div>
        </div>
      </div>

      <div className="flex flex-wrap md:flex-nowrap gap-2 lg:gap-16 p-4 w-full border-2 border-gray-200 rounded-lg">
        <div className="w-full md:w-2/3 shrink-0">
          <div className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></div>       
          <div className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></div>


          <p className="mt-10 leading-relaxed mb-2 w-1/3 h-3 animate-pulse bg-gray-400"></p>
          <p className="leading-relaxed mb-3 w-full rounded-md h-3 animate-pulse bg-gray-400"></p>
        </div>

        <div className="self-center w-full flex md:block md:w-1/3">
          <div className="w-full sm:mb-4 h-14 animate-pulse bg-gray-500 md:rounded-md rounded-l-md rounded-r-none"></div>
          <div className="w-full sm:mb-4 h-14 animate-pulse bg-gray-200 md:rounded-md rounded-r-md rounded-l-none"></div>
        </div>
      </div>
    </div>
  </div>
);
export default PostPurchaseListItemSkeleton;