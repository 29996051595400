import { useRouter } from "next/router";
import { useState, useRef, useEffect } from "react";
import LinkWrapper from "../LinkWrapper";
import { INode } from "./interfaces";

interface IAnchorElement extends INode {
  className: string;
}
const AnchorElement = (props: IAnchorElement) => {
  const className = props.className;
  const innerHTML = props.innerHTML;
  const router = useRouter();
  const [activeSection, setActiveSection] = useState(null);
  const sections = useRef<any>([]);

  const mergeQueryParams = (obj1: { [key: string]: string | string[] }, obj2: { [key: string]: string | string[] }) => {
    const finalObj = { ...obj1 };
    const obj2Keys = Object.keys(obj2);

    for (let key of obj2Keys) {
      if (!finalObj[key]) {
        finalObj[key] = obj2[key];
        continue;
      }
      let obj1Value = finalObj[key];
      let obj2Value = obj2[key];

      if (!Array.isArray(obj1Value)) {
        obj1Value = [...obj1Value.split(",")];
      }

      if (!Array.isArray(obj2Value)) {
        obj2Value = [obj2Value];
      }

      const mergedArr = [...obj1Value, ...obj2Value];
      mergedArr.sort();

      for (let i = 0; i < mergedArr.length; i++) {
        let j = i + 1;
        if (mergedArr[i] === mergedArr[j]) {
          mergedArr.splice(j, 1);
          mergedArr.splice(i, 1);
          j--;
        }
      }

      if (mergedArr.length === 0) {
        delete finalObj[key];
        continue;
      }
      if (mergedArr.length === 1) {
        finalObj[key] = mergedArr[0];
        continue;
      }
      finalObj[key] = mergedArr;
    }
    return finalObj;
  }

  const linkClicked = (e: any) => {
    e.preventDefault();
    const urlToNavigate = e.currentTarget.getAttribute("href");
    if (urlToNavigate === "#") {
      if (!props.meta?.query) {
        return;
      }
      const currentUrl = router.asPath;
      const currentQuery = router.query;
      const finalQueryParams = mergeQueryParams(currentQuery as any, props.meta.query);
      router.push(`${currentUrl}?${new URLSearchParams(finalQueryParams as any).toString()}`);
      return;
    }
    if (!props.meta?.query) {
      router.push(urlToNavigate);
      return;
    }
    router.push(`${urlToNavigate}?${new URLSearchParams(props.meta.query as any).toString()}`);
  }
  const handleScroll = () => {
    const pageYOffset = window.pageYOffset;
    let newActiveSection = null;

    sections.current.forEach((section: any) => {
      // getting current section based on the offset (scroll) 
      const sectionOffsetTop = section.offsetTop - 100;
      const sectionHeight = section.offsetHeight + 100;

      if (pageYOffset >= sectionOffsetTop && pageYOffset < sectionOffsetTop + sectionHeight) {
        // depending on the section id, we can set the active section (navigation link)
        newActiveSection = section.id;
      }
    });

    setActiveSection(newActiveSection);
  };


  useEffect(() => {
    // getting all the sections with id 
    sections.current = document.querySelectorAll(".static-content section[id]");
    if (!sections.current?.length) return;
    window.addEventListener("scroll", handleScroll);

    // removing scroll event on unmount (to avoid memory leaks)
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (props.props && props.meta?.next) {
    const [key, value] = router.asPath.split("?")?.[1]?.split("=") || [];
    let selected = props?.meta?.query?.[key] === value;

    let newClassName = `${className}`;
    if (key && value && selected) {
      newClassName = `${className} bg-primary !text-white`;
    }
    return <LinkWrapper {...props.props} className={newClassName} href={props.props.href} onClick={linkClicked} dangerouslySetInnerHTML={{ __html: innerHTML }} />
  }
  let newClassName = `${className}`;

  let currentHref = props?.props?.href.split("#")[1];
  if (currentHref === activeSection) {
    newClassName = `${className} border-b-2 border-primary py-2 font-bold`;
  }
  return <LinkWrapper {...props.props} className={newClassName} href={props?.props?.href} dangerouslySetInnerHTML={{ __html: innerHTML }} />;
}

export default AnchorElement;