import { useRouter } from "next/router";
import { createElement, useState } from "react";
import FaChevronDown from "../SVG/Fontawesome/FaChevronDown";
import { IHTMLSection } from "./interfaces";
import Section from "./Section";

const ChildItem = (props: any) => {

  return createElement(props.children?.nodeName?.toLowerCase() || "div", {
    ...props.children.props,
  },
  props.children?.meta?.defaultShowCount
    ? props.childItems
      .slice(0, props.count)
      .map((child: IHTMLSection, i: number) => (
        <Section key={i.toString()} {...child} />
      ))
    : props.children?.items?.map((child: IHTMLSection, i: number) => (
      <Section key={i.toString()} {...child} />
    )))  
}


const SectionChildren = (props: IHTMLSection) => {
  const router = useRouter();

  // split the query string and get first param key and value
  const [key, value] = router.asPath.split("?")?.[1]?.split("=") || [];

  let childItems = props.children?.items || [];

  // if params key and value is present in query string and we want to filter children items (meta filter is true)
  if(key && value && props.children?.meta?.filter){
    // filter based on query string
    childItems = props.children?.items?.filter((child:any ) => child?.meta[key] == value);
  }

  const [count, updateCount] = useState(
    props.children?.meta?.defaultShowCount || props.children?.items?.length
  );

  return <>
    {props.children && <ChildItem {...props} count={count} childItems={childItems}/>}
    {props.children?.meta?.defaultShowCount &&
  count < childItems?.length && (
      <a
        className="btn cursor-pointer hover:opacity-70 flex gap-1 justify-center items-center mt-6 !text-slate-500"
        onClick={() =>
          updateCount((previous: number) => {
            return previous + props.children?.meta?.viewMoreCount;
          })
        }
      >
      View More
        <FaChevronDown className="!text-slate-500" />
      </a>
    )}
  </>;
}

export default SectionChildren;