import Image from "next/image";
import Shimmer from "@/utils/shimmer";
import { IImageBannerData } from "../Banner/interface";

const ImageBanner = (props: {
  eagerLoad?: boolean;
  className?: string;
  id?: string;
  data: IImageBannerData
}) => {
  const {
    href,
    imgUrl,
    alt
  } = props.data;

  const extraProps: any = {};
  if (props.id) {
    extraProps.id = props.id;
  }

  const styleProps: any = {};
  if (props.data.width) {
    styleProps.maxWidth = `${props.data.width}px`;
  }
  if (props.data.height) {
    styleProps.maxHeight = `${props.data.height}px`;
  }

  if (href) {
    return <div {...extraProps}>
      <a style={styleProps} className={`my-4 block max-w-full mx-auto ${props.className ?? ""}`} href={href} target="_blank" rel="noopener noreferrer noindex nofollow">
        <Image priority={!!props.eagerLoad} className="!my-0 w-full" src={imgUrl} width={props.data.width || 300} height={props.data.height || 200} alt={alt ?? ""} placeholder="blur" blurDataURL={Shimmer(props.data.width || 300, props.data.height || 200)} />
      </a>
    </div>
  }
  return <div {...extraProps} style={styleProps} className={`my-4 block max-w-full mx-auto ${props.className ?? ""}`}>
    <Image priority={!!props.eagerLoad} className="!my-0 w-full" src={imgUrl} width={props.data.width || 300} height={props.data.height || 200} alt={alt ?? ""} placeholder="blur" blurDataURL={Shimmer(props.data.width || 300, props.data.height || 200)} />
  </div>
}

export default ImageBanner;