import { BACKEND_API, MICRO_API } from "@/config/constants";
import { apiClient } from "@/helpers/request";

export const autoSuggest = (text: string): Promise<{ data: any }> => {
  return apiClient(BACKEND_API, "/v5/search/autoSuggest", {
    method: "POST",
    body: JSON.stringify({
      featureIds: {
        ias_suggester: {
          variant_id: 1617,
          index: "in_app_search_suggester",
          index_identifier: "v2.7",
          version: "v6",
          enabled: true
        },
      },
      text,
      source: "WEB",
    })
  });
}

export const getDefaultSuggestions = (): Promise<{ meta: any; data: any }> => {
  return apiClient(BACKEND_API, "/v5/search/getSuggestions", {
    method: "POST",
    body: JSON.stringify({
      featureIds: {
        ias_LiveNow_TopTag: false
      },
      sessionId: 1,
      source: "WEB",
    })
  });
}

export const inAppSearch = (text: string): Promise<{ data: any }> => {
  return apiClient(MICRO_API, "/search/matches", {
    method: "POST",
    // headers: {
    //   version_code: "2000",
    // },
    body: JSON.stringify({
      text,
      source: "WEB",
    })
  });
}

export const getPlaylistData = (id: string, package_details_id: string, student_class: number): Promise<{ data: any }> => {
  return apiClient(BACKEND_API, "/v7/library/getplaylist", {
    headers: {
      version_code: "2000",
    },
    query: {
      page_no: 1,
      id,
      student_class,
      package_details_id: package_details_id || "",
    }
  });
}

export const getPlaylistResource = (id: string, package_details_id: string): Promise<{ data: any }> => {
  return apiClient(BACKEND_API, "/v8/library/getresource", {
    headers: {
      version_code: "2000",
    },
    query: {
      page_no: 1,
      id,
      package_details_id: package_details_id || "",
      auto_play_data: 0,
      supported_media_type: "DASH,HLS,BLOB",
    }
  });
}