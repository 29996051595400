import { useRouter } from "next/router";
import ArrowToTopLeftIcon from "../SVG/ArrowToTopLeftIcon";
import { getMatches } from "@/helpers/api/service/ask";
import { getUUID } from "@/utils/auth";
import { useDispatch } from "react-redux";
import { offCanvasInitialState, updateOffCanvasView } from "@/store/slices/offCanvas";

const AutoComplete = (props: { suggestions?: any[]; inputRef: any }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const newSearchQuery = async (text: string) => {
    if (!text) return;
    const { data } = await getMatches(getUUID(), undefined, undefined, text);
    if (!data) return;
    dispatch(updateOffCanvasView({
      name: "CAMERA",
      data: {
        CAMERA: {
          ...offCanvasInitialState.data.CAMERA,
          text,
          questionId: data.question_id,
          matchedQuestions: data.matched_questions ?? [],
        },
      }
    }));
  }

  const onSuggestHandler = (x: any) => {
    if (!props.inputRef.current) {
      return;
    }
    props.inputRef.current.value = x.display.replace(/\\/g, "");
    props.inputRef.current.focus();
  }

  if (!props.suggestions?.length) {
    return <></>
  }

  return (
    <>
      {
        props.suggestions.map((x, i) =>
          <div key={i.toString()} className="w-full flex items-center justify-between border-b last:border-none bg-secondary-light z-10">
            <div key={i} className="suggestion px-2 py-3 grow" onClick={() => newSearchQuery(x.display)}>{x.display.replace(/\\/g, "")}</div>
            <span className="fill-gray-400" onClick={() => onSuggestHandler(x)}>
              <ArrowToTopLeftIcon />
            </span>
          </div>
        )
      }
    </>
  );
}

export default AutoComplete;