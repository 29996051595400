import { offCanvasInitialState, updateOffCanvasView, updateOffCanvasViewPayload } from "@/store/slices/offCanvas";
import { useEffect, useState } from "react";
import FaMicrophone from "../SVG/Fontawesome/FaMicrophone";
import { useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";

const VoiceSearchIcon = () => {
  const [speechRecognitionAvailable, setSpeechRecognitionAvailable] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition || window.oSpeechRecognition;

    if (!SpeechRecognition) {
      setSpeechRecognitionAvailable(false);
    }
  }, [])

  if (!speechRecognitionAvailable) return <></>;

  return <>
    <span>|</span>
    <button id="hd-mic" name='hd-mic' onClick={() => {
      if (isMobile) {
        dispatch(updateOffCanvasView({ name: "CAMERA", data: { CAMERA: { ...offCanvasInitialState.data.CAMERA, cameraBSTabIndex: 0 } } }));
        return;
      }
      dispatch(updateOffCanvasView({ name: "VOICE_SEARCH" }));
    }}>
      <FaMicrophone />
    </button >
  </>
}

export default VoiceSearchIcon;