import { STATIC_CDN } from "@/config/constants";
import Shimmer from "@/utils/shimmer";
import Image from "next/image";
import AppStoreBadge from "../Badge/AppStore";
import PlayStoreBadge from "../Badge/PlayStore";

export const DownloadIcons = (props: { promoText?: string }) => {
  return <div className="lg:px-16 flex flex-col lg:basis-3/5 p-4 items-center">
    <h3>Download Our App</h3>
    <p>{
      props.promoText ??
      "Doubtnut is the No. 1 Study App with India's Best Teachers for Class 6 to 12 upto IIT-JEE, NEET & Other Government Exams. Available on both Android and iOS"
    }</p>

    <div className="grid sm:grid-cols-2 items-center">
      <PlayStoreBadge linkUrl="https://doubtnut.app.link/X6wgekbjKBb" />
      <AppStoreBadge linkUrl="https://tiny.doubtnut.com/iOS-app-3" />
    </div>
  </div>
}

const DownloadAppV2 = () => {
  return <section className="flex flex-col items-center mt-12">
    <div className='flex flex-wrap justify-between items-center p-6'>
      <div className="lg:pr-4 lg:basis-2/5">
        <Image
          src={`${STATIC_CDN}/engagement_framework/22C7A42E-AD8B-D41B-B543-FF93584A9995.webp`}
          width={211}
          height={65}
          alt='Doubtnut on Google Play'
          className='w-full h-auto my-0'
          placeholder="blur"
          blurDataURL={Shimmer(211, 65)}
        />
      </div>
      <DownloadIcons />
    </div>
  </section>
}

export default DownloadAppV2;