import { createElement } from "react";
import Content from ".";
import { IHTMLSection } from "./interfaces";
import SectionChildren from "./SectionChildren";
import MyCourseSection from "./MyCourseSection";

const ContentItem = (props: IHTMLSection) => {
  return createElement(props.content?.nodeName?.toLowerCase() || "div", {
    ...props.content?.props,
  },
  props.content?.items?.map((node, index) => (
    <Content key={index.toString()} {...node} />
  ))
  )
}; 

const Section = (props: IHTMLSection) => {
  return <> 
    {
      createElement(props.nodeName?.toLowerCase() || "div", {
        ...props.props,
      },
      <>
        {props.title && <Content {...props.title} /> }
        {props.content && <ContentItem {...props} /> }
        {/* {
      (props.title || props.content?.items?.length) && props.children?.items?.length && <Content nodeName="BANNER_AD" />
    } */}
        <SectionChildren {...props} />
      </>
      )
    }
    {props?.props?.id === "hero-section" && <MyCourseSection {...props} />}
  </>
};

export default Section;