import Shimmer from "@/utils/shimmer";
import Image from "next/image";
import { IBookList, IBookListItem } from "./interfaces";
import LinkWrapper from "../LinkWrapper";

const BookListItem = (props: { item: IBookListItem }) => {
  const item = props.item;

  return <li className="pl-0">
    <LinkWrapper className="flex p-2 gap-2 h-full link hover:!no-underline hover:bg-secondary hover-pop rounded-xl" href={item.url}>
      <Image className="w-24 sm:w-32 h-full rounded-xl border-2 border-transparent my-0" src={item.thumbnail} alt={item.alt} width={128} height={180}
        placeholder="blur"
        blurDataURL={Shimmer(128, 180)} />
      <div className="space-y-2">
        <h3 className="text-base text-medium my-0">{item.subtitle}</h3>
        {item.description && <p className="text-sm text-gray-500">{item.description}</p>}
      </div>
    </LinkWrapper>
  </li>
}

const BookList = (props: IBookList) => {
  return <ul className={`list-none grid gap-4 lg:grid-cols-3 pl-0  ${props.className ?? ""}`}>
    {props.items.map((item, index) => (
      <BookListItem key={index.toString()} item={item} />
    ))}
  </ul>
};

export default BookList;