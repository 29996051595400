import { getPurchasedCourses } from "@/helpers/api/service/Course";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { IMyCourseList } from "../CourseWidget/interfaces";
import MyCourseList from "../MyCourseList";
import { IHTMLSection } from "./interfaces";
import { useSelector } from "react-redux";
import { selectCourseEnabled } from "@/store/slices/auth";

const MyCourseSection = (props: IHTMLSection) => {
  const [myCourseList, updatemyCourseList] = useState<{ widget_data: IMyCourseList }>();
  const router = useRouter();
  const courseEnabled = useSelector(selectCourseEnabled);

  useEffect(() => {
    if (router.asPath !== "/home" || props?.props?.id !== "hero-section") return;
    if (!courseEnabled) {
      router.replace("/", undefined, { shallow: true });
      return;
    }
    getPurchasedCourses().then((res) => {
      if (!res.data?.widget_data?.items?.length) {
        return;
      }
      updatemyCourseList(res.data);
    });

  }, [router.asPath, courseEnabled, props.props?.id])

  if (!myCourseList?.widget_data?.items?.length) return null;

  return <MyCourseList widget_data={myCourseList.widget_data} />;
};

export default MyCourseSection;