import LinkWrapper from "../LinkWrapper";
import FaChevronRight from "../SVG/Fontawesome/FaChevronRight";
import { IRelatedLinks } from "./interfaces";

const RelatedLinks = (props: IRelatedLinks) => {
  const maxCount = props.maxCount || props.items.length;
  return (
    <div>
      <h2>{props.title}</h2>
      <ul className={`list-none pl-0 text-slate-300 ${props.className ?? ""}`}>
        {props.items.slice(-0.1, maxCount).map((item, i) => {
          return (
            <li className={`${props.itemClassName ?? ""}`} key={i.toString()}>
              <LinkWrapper href={item.url} className="link pl-0 pr-0">
                <span>{item.text}</span>
              </LinkWrapper>
            </li>
          );
        })}

        {props.viewMore && (
          <li>
            <LinkWrapper href={props.viewMore.url} className="link">
              {props.viewMore.text} <FaChevronRight />
            </LinkWrapper>
          </li>
        )}
      </ul>
    </div>
  );
};

export default RelatedLinks;
