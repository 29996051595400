import Image from "next/image";

const PlayStoreBadge = (props:{linkUrl?:string }) => {
  return (
    <div>
      <a
        href={props.linkUrl ? props.linkUrl : "https://play.google.com/store/apps/details?id=com.doubtnutapp&hl=en&gl=US"}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Image
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          width={211}
          height={65}
          alt="Doubtnut android app on Google Play Store"
          className="w-44 h-auto my-0"
        />
      </a>
    </div>
  );
}

export default PlayStoreBadge;