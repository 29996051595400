import LinkWrapper from "../LinkWrapper";
import TopIcon from "../SVG/TopIcon";

const TopIconList = (props: {className?: string; items:{href:string, className:string; text:string}[]}) =>{
  return <div className={`grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 ${props.className ?? ""}`}>
    {props.items.map((item, index)=>(
      <LinkWrapper href={item.href} key={index.toString()}> 
        <TopIcon className={item.className} text={item.text}/>
      </LinkWrapper>
    ))}
  </div>
}
export default TopIconList;