const TopIcon = (props: { className: string; text: string }) => {
  return (
    <div className={`hover-pop cursor-pointer aspect-square w-full flex items-center justify-center relative rounded-3xl ${props.className ?? ""}`}
      style={{
        backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAABcElEQVRIicWX/0vDMBDFn8UiFgmW4RemY///nzUEmZMijDKRblYCLxBLt75bs+1g5Je7+1wul5f1qm1bXMIykZkDmKasTwU3AH4AlOcGe/sCcA/g5tzgXwAVW26JOwi+E/3XALYAHlOCCzHG79oZij0I9smexBZuuPPnMS0PgU2UTLGKPi9jweDUFmILG/rfAphFRxV++VCCrnI5Ds6CyYeKnguQb647akHt1z7JnPPqvA1VTWgAbwT/f1V37ZMtnAjxDYEm6D5wmNpJKpVSwSAYqVTKAj657QM7ru8ctOTWBy4Irjj+J7HrnqQPvHuVAAzXaWctsgt2TLQQYn23Xg0CgujaVTE4o2otBdUC/43knIEVn8tgzVCOGFyyolqA5vSvWah5AMNw5WzzUowLqnYUNAb7RB9ikiIq8uirFh6JzJBkxvNTu9NrYccq1HEuVmOgMEpmxiNJomYWcMnHI4maWT5h/BOpqJlkl/loA/AHCE9aaujLTkIAAAAASUVORK5CYII=')`
      }}>
      <div className="flex flex-col text-white text-center">
        {
          props.text.split(" ").map((x, i) => (
            <span key={i.toString()} className="text-lg sm:text-xl md:text-2xl xl:text-3xl font-medium">{x}</span>
          ))
        }
      </div>
    </div>
  );
}

export default TopIcon;