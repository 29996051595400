import { Suspense } from "react";
import { IVideoPlayer } from "@/helpers/VideoPlayer/interface";
import dynamic from "next/dynamic";
import Shimmer from "@/utils/shimmer";
import Image from "next/image";
import { useSelector } from "react-redux";
import { selectPromoCompanion } from "@/store/slices/promoCompanion";
import { selectProEnabled } from "@/store/slices/auth";
const Player = dynamic(() => import("./VideoPlayer"), { ssr: false });

const PlayerFallback = (props: {
  poster?: string;
}) => {
  return <div className="relative cursor-pointer embed-responsive embed-responsive-16by9 aspect-video w-full bg-gray-700 lg:rounded-md text-white">
    {props.poster && <Image src={props.poster} className="m-0 w-full lg:rounded-md" alt="" width={640} height={360} placeholder="blur" blurDataURL={Shimmer(640, 360)} />}
  </div>
}

const VideoPlayer = (props: IVideoPlayer) => {
  const companionData = useSelector(selectPromoCompanion);
  const proEnabled = useSelector(selectProEnabled);

  return (
    // adding key on player is causing issues as it's dynamic import - in case of authContext change when users (logins or logout).
    <div key={`${props.videoData.question_id}-${proEnabled}`} className={`not-prose relative embed-responsive embed-responsive-16by9 w-full lg:min-w-[320px] aspect-video overflow-hidden flex items-center justify-center ${companionData ? "border-4" : ""} ${props.className ?? ""}`}>
      <Suspense fallback={<PlayerFallback poster={props.poster}/>}>
        <Player
          {...props}
          autoplay={props.autoplay || props.isInModal}
          avoidAds
        />
      </Suspense>
    </div >
  );
}

export default VideoPlayer;