import PostPurchaseList from "@/components/CourseWidget/PostPurchaseList";
import PostPurchaseListItemSkeleton from "@/components/Skeleton/PostPurchaseListItemSkeleton";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getPurchasedCourses } from "@/helpers/api/service/Course";
import { IMyCourseList } from "../CourseWidget/interfaces";
import { useSelector } from "react-redux";
import { selectCourseEnabled, selectUser } from "@/store/slices/auth";

const MyCourseList = (props: {
  widget_data?: IMyCourseList
}) => {
  const [courses, setCourses] = useState<{
    widget_data?: IMyCourseList;
  }>(props);
  const courseEnabled = useSelector(selectCourseEnabled);
  const router = useRouter();

  useEffect(() => {
    if (props.widget_data) return;
    if (!courseEnabled) {
      router.replace("/course");
      return;
    }

    getPurchasedCourses().then((res) => {
      if (!res.data?.widget_data?.items?.length) {
        router.replace("/course");
        return;
      }
      setCourses(res.data);
    });
  }, [courseEnabled, props.widget_data]);

  if (!courseEnabled || !courses?.widget_data?.items?.length) {
    return <PostPurchaseListItemSkeleton />
  }
  
  return (
    <div className="body-content flex flex-wrap gap-3 px-2.5">
      <PostPurchaseList courses={courses}/>
    </div>
  );
};

export default MyCourseList;