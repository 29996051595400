export const nodeBeautifier = (nodeName: string) => {
  const commonClassName = "";
  let className = "";
  switch (nodeName?.toUpperCase()) {
    case "H1":
      className = "px-2 lg:px-0";
      break;
    case "A":
      className = "link";
      break;
    case "UL":
      className = "card px-8";
      break;
    case "TABLE":
      className = "card p-0 mb-4";
      break;
    case "TH-TD":
      className = "font-bold bg-secondary-dark rounded p-2 text-center break-words";
      break;
    case "TR-TD":
      className = "font-normal border border-secondary-dark rounded px-2 break-words";
      break;
    default:
      className = "";
  }
  return [commonClassName, className].filter(Boolean).join(" ");
}