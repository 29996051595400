import Shimmer from "@/utils/shimmer";
import Image from "next/image";
import { IBookList, IBookListItem } from "./interfaces";
import LinkWrapper from "../LinkWrapper";
import Label from "../Badge/Label";
import { useState } from "react";
import FaChevronRight from "../SVG/Fontawesome/FaChevronRight";
import FaChevronDown from "../SVG/Fontawesome/FaChevronDown";

export const BookListV2Item = (props: { item: IBookListItem }) => {
  const item = props.item;

  return <li className="pl-0 my-0 border-b-2 last:border-none">
    <LinkWrapper className="flex p-2 gap-2 h-full link hover:!no-underline hover:bg-secondary hover-pop rounded-xl" href={item.url}>
      {
        item.thumbnail && <Image className="w-16 h-full rounded-md border-2 border-transparent my-0" src={item.thumbnail} alt={item.alt} width={64} height={90}
          placeholder="blur"
          blurDataURL={Shimmer(64, 90)} />
      }
      <div className="space-y-2">
        <h3 className="text-sm font-medium my-0">{item.subtitle}</h3>
        <div className="flex flex-wrap items-center gap-1">
          {
            item.labels?.map((label, index) => (
              <Label key={index.toString()} text={label} />
            ))
          }
        </div>
      </div>
    </LinkWrapper>
  </li>
}

const BookListV2 = (props: IBookList) => {
  const [viewCount, setViewCount] = useState<number>(6);

  const hasMoreItems = props.items.length > viewCount;

  return <>
    <ul className={`list-none mb-0 grid gap-x-4 lg:grid-cols-2 pl-0  ${props.className ?? ""}`}>
      {
        props.items.slice(0, viewCount).map((item, index) => (
          <BookListV2Item key={index.toString()} item={item} />
        ))
      }
    </ul>
    <div className="flex justify-center">
      {
        props.viewAllHref && <LinkWrapper className="link w-max text-sm" href={props.viewAllHref}>View All <FaChevronRight /></LinkWrapper>
      }
      {
        !props.viewAllHref && hasMoreItems && <button className="link w-max text-sm mt-4" onClick={() => setViewCount(viewCount + 6)}>View More <FaChevronDown /></button>
      }
    </div>
  </>
};

export default BookListV2;