import { STATIC_CDN } from "@/config/constants";
import Image from "next/image";

const AppStoreBadge = (props: { linkUrl?: string }) => {
  return (
    <div>
      <a
        href={props.linkUrl ? props.linkUrl : "https://apps.apple.com/in/app/doubtnut-q-a-app/id1672025804"}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Image
          src={`${STATIC_CDN}/engagement_framework/4D6D5BE6-3D00-D2D9-AA03-06E1F488DC39.webp`}
          width={211}
          height={65}
          alt="Doubtnut iOS app on App Store"
          className="w-44 h-auto my-0 px-3"
        />
      </a>
    </div>
  );
}

export default AppStoreBadge;